/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    hr: "hr",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Aš įtariu rūkalius susimokymu. Nuo praeito įrašo niekas dar nepaprašė\nrūkyt, nors iki šiol statistiškai būdavo vienas-kablelis-penki prašytojo\nsavaitėje."), "\n", React.createElement(_components.p, null, "Aš juos stebiu."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Ataskaita dabar bus savaitgalinis reikalas. Savaitgalis, nežinantiems –\ndienos nuo šeštadienio iki pirmadienio imtinai. Ir nereikia man pasakot\nkaip aš kažko nepadariau."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "Sekmadieniu (vakar) pažymėtam įraše turiu pakeverzojęs „savaitės minčių\nstygius – glaudžiai susijęs su darbo įdomumu: kuo daugiau nuoširdžiai\ndirbu, tuo mažiau turiu ką papasakoti.“"), "\n", React.createElement(_components.p, null, "Įtariu, kad tiesos yra, nes šita ataskaita trumpiausia."), "\n", React.createElement(_components.p, null, "Šiandien dar nusprendžiau, kad reikės kada\n", React.createElement(_components.a, {
    href: "http://www.interakcijos.lt/"
  }, "interakcijoms"), " pasiūlyti pastraipsniuoti.\nNesu tikras ar jie linkę į dizainą, procesą ar saviiešką, ale pajudint\nbūtų smagu. Tuo pačiu ir pasidaryčiau ", React.createElement(_components.em, null, "published"), " autorius, kas jau\nsavaime reiškia, kad galėsiu autobusais važinėt veik už dyką."), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.p, null, "O šiaip tai daug svarbiau yra tai, kas patinka man rezultate, kurį gaunu\nperfiltravęs visus projekto reikalavimus ir taikinius, nei tai, ką aš\nįsivaizduoju esant kietu daiktu. Mano akis yra geriausia akis. Man."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
